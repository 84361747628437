import { BrowserRouter, Routes, Route, NavLink, Link } from 'react-router-dom'
import { lazy, Suspense, Component } from 'react'
import ReactTimeout from 'react-timeout'
import { API_USERS_ALL_URL, API_DREAMSALL_URL, API_DREAMSREADY_URL, API_DREAMSFALSE_URL } from './props'
import axios from 'axios'
import moment from 'moment'
import './css/bg.css'
import './css/bg-back.css'
import Home from './Home'
import Sun from './img/sun.png'
import Moon from './img/moon.png'
import Zoom from 'react-reveal/Zoom'

const GlitchSquiggly = lazy(() => import('react-glitch-effect/core/GlitchSquiggly'))
const Dreams = lazy(() => import('./Dreams'))
const DreamsReady = lazy(() => import('./DreamsReady'))
const DreamsFalse = lazy(() => import('./DreamsFalse'))
const NewDream = lazy(() => import('./NewDream'))
const DreamDetail = lazy(() => import('./DreamDetail'))
const DreamEdit = lazy(() => import('./DreamEdit'))
const Register = lazy(() => import('./Register'))
const User = lazy(() => import('./User'))
const Login = lazy(() => import('./Login'))
const UserEdit = lazy(() => import('./UserEdit'))
const Сallback = lazy(() => import('./Сallback'))
const Remind = lazy(() => import('./Remind'))

class App extends Component {
  constructor(props){
    super(props)
    this.logout = this.logout.bind(this)
    this.login = this.login.bind(this)
    this.timer = this.timer.bind(this)
    this.changeBg = this.changeBg.bind(this)
    this.loginError  = this.loginError.bind(this)
    this.dreamReady = this.dreamReady.bind(this)
    this.dreamFalse = this.dreamFalse.bind(this)
    this.sun = this.sun.bind(this)
    this.state = {savedUsername: localStorage.getItem('user'), logoutLink: null, users: 0, dreamsFalse: 0, dreamsReady: 0, bgBack: false, bgChanged: false, loginErrors: 0}
  }

  async componentDidMount(){
      const time = Math.floor(Math.random() * (31 - 16 + 1) + 16)
      this.changeBg(time)

      fetch(API_DREAMSREADY_URL + "?user=1", {headers: {'Content-Type': 'application/json'}})
      .then(resp => resp.json())
      .then(data => this.setState(() => ({ dreamsReady: Number(data.length) })))

      fetch(API_DREAMSFALSE_URL + "?user=1", {headers: {'Content-Type': 'application/json'}})
      .then(resp => resp.json())
      .then(data => this.setState(() => ({ dreamsFalse: Number(data.length) })))   
      
      fetch(API_USERS_ALL_URL, {headers: {'Content-Type': 'application/json'}})
      .then(resp => resp.json())
      .then(data => this.setState(() => ({ users: Number(data.length) })))  

  }  

 
  sun() {this.setState((state) => ({ sun: !state.sun }))}
  
  dreamReady () {
    axios.get(API_DREAMSALL_URL, {params: {status: 1, user: this.state.savedUsername }}).then(response => this.setState({dreamsReady: (this.state.dreamsReady + 1), dreamsFalse: (this.state.dreamsFalse - 1)}))
  }
  dreamFalse () {
    axios.get(API_DREAMSALL_URL, {params: {status: 0, user: this.state.savedUsername }}).then(response => this.setState({dreamsReady: (this.state.dreamsReady - 1), dreamsFalse: (this.state.dreamsFalse + 1)}))
  }  
  logout() {
    localStorage.removeItem('user')
    this.setState(() => ({ savedUsername: null }))
  }
  login(username) {
    localStorage.setItem('user', username)
    this.setState ({sun: false, savedUsername: localStorage.getItem('user')})
  } 
  loginError(error){
    let loginErrors = this.state.loginErrors
    if(error === 1)
      loginErrors += 1
    else
      loginErrors = 0
    this.setState({loginErrors: loginErrors})
  }

  timer(){
    if(this.state.bgBack === true){
      this.setState({bgBack: false})
      const time = Math.floor(Math.random() * (41 - 31 + 1) + 31)
      this.changeBg(time)
    }else{
      this.setState({bgBack: true})
      this.props.setTimeout(this.timer, 2000) 
    }
  }  

  changeBg(time){
    this.props.setTimeout(this.timer, time * 1000) 
  }  

  render(){
    const { savedUsername, loginErrors, bgBack } = this.state
    const timeNow = moment().format('H')
    let momentNow = false
    if (timeNow > 8 & timeNow < 19)
        momentNow = true 
    let bgNight = ''
        bgNight = momentNow === false && 'night'
    return(
      <BrowserRouter>
      <section id="wrap">
      <aside id="clouds" className={!momentNow && 'bg-night'}><aside className="cloud x1"></aside><aside className="cloud x2"></aside><aside className="cloud x3"></aside><aside className="cloud x4"></aside><aside className="cloud x5"></aside><aside className="cloud x6"></aside></aside>  
      <div id="bg" className={bgBack ? 'glitch' : bgNight}>
      <GlitchSquiggly true baseFrequency="0.316" scaleNoise="6.13" duration="916">
        <Link to="/">{momentNow ? <img id="sun" src={Sun} className={this.state.sun && 'sun'} alt="Переход домой" /> : <img id="moon" src={Moon} alt="Переход домой" />}</Link>
        <aside id="logo">
          <h1 className="navbar-item jt --debug">            
            <span className="jt__row"><span className="jt__text"><b>Dream</b>Landia.net</span></span>
            <span className="jt__row jt__row--sibling" aria-hidden="true"><span className="jt__text"><b>Dream</b>Landia.net</span></span>
            <span className="jt__row jt__row--sibling" aria-hidden="true"><span className="jt__text"><b>Dream</b>Landia.net</span></span>
            <span className="jt__row jt__row--sibling" aria-hidden="true"><span className="jt__text"><b>Dream</b>Landia.net</span></span>
          </h1>
        </aside>
      </GlitchSquiggly>
      </div>
      
      <div id="stats">
       <div className="row">
        <div className="col"><i className="bi bi-person"></i> {this.state.users}</div>
        <div className="col"><i className="bi bi-magic"></i> {this.state.dreamsFalse}</div>
        <div className="col"><i className="bi bi-brightness-high"></i> {this.state.dreamsReady}</div>
       </div> 
      </div>

        <nav className="navbar">
          <Zoom bottom right cascade delay="1500">
          <div className="navbar-menu">
            {!this.state.savedUsername && (<NavLink to='/' className={({ isActive }) => 'navbar-item' + (isActive ? ' active' : '')}><i className='bi bi-house-up'></i><span>домашняя</span></NavLink>)}  
            {!this.state.savedUsername && (<NavLink to='/register' className={({ isActive }) => 'navbar-item' + (isActive ? ' active' : '')}><i className='bi bi-pencil-square'></i><span>регистрация</span></NavLink>)}    
            { this.state.savedUsername && (<NavLink to="/user" className={({ isActive }) => 'navbar-item' + (isActive ? ' active' : '')}><i className='bi bi-house-up'></i><span>профиль</span></NavLink>)}
            { this.state.savedUsername && (<NavLink to="/append" className={({ isActive }) => 'navbar-item' + (isActive ? ' active' : '')}><i className='bi bi-magic'></i><span>+ желание</span></NavLink>)}
            {!this.state.savedUsername && (<NavLink to='/login' className={({ isActive }) => 'navbar-item desktop' + (isActive ? ' active' : '')}><i className='bi bi-power'></i><span>логин</span></NavLink>)} 
            { this.state.savedUsername && (<Link className='navbar-item desktop' onClick={this.logout}><i className='bi bi-escape'></i><span>выход</span></Link>)} 
            <NavLink to="/callback" className={({ isActive }) => 'navbar-item' + (isActive ? ' active' : '')}><i className="bi bi-envelope-at"></i><span>контакт</span></NavLink>
          </div>
          </Zoom>
        </nav>
        
        <main className="content">
        <div className="navbar-brand" id="logo-small">
           <Link to="/"><h1 className="navbar-item small"><b>Dream</b>Landia.net</h1></Link>
           <aside className={this.state.savedUsername && 'active'}>{this.state.savedUsername ? <Link className='navbar-item' onClick={this.logout}><i className='bi bi-escape'></i><br /><span>выкл</span></Link> : <Link to='/login' className="navbar-item"><i className='bi bi-power'></i><br /><span>вход</span></Link>} </aside>
        </div>
        <Routes>
          <Route path="/" element={<Home sun={this.sun} />} />
          <Route path="/user" element={<Suspense fallbak={<p>Идет загрузка</p>}><User savedUsername={this.state.savedUsername} /></Suspense>} />
          <Route path="/user/edit" element={<Suspense fallbak={<p>Идет загрузка</p>}><UserEdit sun={this.sun} savedUsername={this.state.savedUsername} /></Suspense>} />
          <Route path="/register" element={<Suspense fallbak={<p>Идет загрузка</p>}><Register sun={this.sun} login={this.login} savedUsername={this.state.savedUsername} /></Suspense>} /> 
          <Route path="/login" element={<Suspense fallbak={<p>Идет загрузка</p>}><Login sun={this.sun} loginErrors={loginErrors} loginError={this.loginError} savedUsername={this.state.savedUsername} login={this.login} /></Suspense>} /> 
          <Route path="/append" element={<Suspense fallbak={<p>Идет загрузка</p>}><NewDream sun={this.sun} savedUsername={this.state.savedUsername} /></Suspense>} />
          <Route path="/dreams" element={<Suspense fallbak={<p>Идет загрузка</p>}><Dreams savedUsername={this.state.savedUsername} /></Suspense>} />
          <Route path="/dreams-ready" element={<Suspense fallbak={<p>Идет загрузка</p>}><DreamsReady savedUsername={this.state.savedUsername} /></Suspense>} />
          <Route path="/dreams-false" element={<Suspense fallbak={<p>Идет загрузка</p>}><DreamsFalse savedUsername={this.state.savedUsername} /></Suspense>} />
          <Route path="/dreams/:key" element={<Suspense fallbak={<p>Идет загрузка</p>}><DreamDetail savedUsername={this.state.savedUsername} dreamReady={this.dreamReady} dreamFalse={this.dreamFalse} /></Suspense>} /> 
          <Route path="/dreams/edit/:key" element={<Suspense fallbak={<p>Идет загрузка</p>}><DreamEdit savedUsername={this.state.savedUsername} /></Suspense>} /> 
          <Route path="/callback" element={<Suspense fallbak={<p>Идет загрузка</p>}><Сallback sun={this.sun} savedUsername={this.state.savedUsername} /></Suspense>} />
          <Route path="/remind" element={<Suspense fallbak={<p>Идет загрузка</p>}><Remind sun={this.sun} loginError={this.loginError} /></Suspense>} />
        </Routes>  
        </main>
      </section>
      </BrowserRouter>
    )
  }
}

export default ReactTimeout(App)
